export default {
  page: {
    title: {
      base: 'Platební brána Autopay',
      start: 'Výběr způsobu platby',
      visadata: 'Visa Mobile',
      continue: 'Pokračovat – výběr způsobu platby',
      wait: 'Očekávání',
      confirmation: 'Potvrzení platby',
      thankYou: 'Děkuji za platbu',
      error: 'Chyba stránky',
      errorNotFound: 'Stránka nenalezena',
      secureBack: 'Načítání stránky',
      maintenance: 'Webové stránky jsou v údržbě',
      regulationsApproval: 'Předpisy a zásady ochrany osobních údajů',
      toTransferData: 'údaje o převodu',
      sessionTimeout: 'Stránka po ukončení sezení',
      paywayError: 'Platební kanál není dostupný'
    }
  },
  header: {
    availabilityAdjust: {
      increaseFontSize: 'Zvětšete velikost písma',
      decreaseFontSize: 'Zmenšete velikost písma',
      changeContrast: 'Změňte kontrast',
      changeLanguage: 'Změňte jazyk webu',
      skipToContent: 'Přejít na obsah',
      skipToContentLabel: 'Přejít na hlavní obsah',
      logoTitle: 'Logo webu'
    },
    sessionTimer: {
      timeOut: 'Relace vypršela',
      sessionEnd: 'Konec relace za:',
      label: 'Prodlužte čas relace',
      button: 'Prodloužit'
    }
  },
  footer: {
    text1: 'Máte další otázky týkající se online plateb nebo  našich dalších služeb? <a title="Otevře se v novém okně" href="https://pomoc.autopay.pl/?pk_campaign=paywall&pk_kwd=start&pk_medium=hyperlink" class="footer-text-link" target="_blank" rel="noreferrer">Přejděte na stránku nápovědy</a>',
    text2: {
      part1: 'Správcem osobních údajů je Autopay',
      part2: 'ul. Powstańców Warszawy 6, 81-718 Sopot. Poskytnutí osobních údajů je dobrovolné, ale nezbytné k vyplnění odeslané žádosti. Právní základ, účel, doba zpracování osobních údajů a práva Uživatele, jakož i další důležité informace týkající se pravidel pro zpracování osobních údajů jsou podrobně uvedeny v <a href="{privacyPolicyUrl}" class="footer-text-link" target="_blank" title="Přejděte na stránku s dokumentem Zásady ochrany osobních údajů (128 KB, PDF)" rel="noreferrer">Zásady ochrany osobních údajů společnosti Autopay SA</a>'
    },
    linkShowMoreTitle: 'Zobrazit další informace',
    linkShowMoreText: 'Čtěte více',
    linkShowLessTitle: 'Skrýt další informace',
    linkShowLessText: 'Čtěte méně'
  },
  paywall: {
    header: 'Vyberte způsob platby',
    info: {
      paywayNotAvailable: 'Je nám líto, ale zdá se, že vámi zvolený způsob platby v tuto chvíli na vašem zařízení není možný.',
      applePayNotAvailable: 'Je nám líto, ale zdá se, že placení pomocí Apple Pay na vašem zařízení v tuto chvíli není možné.',
      googlePayNotAvailable: 'Je nám líto, ale zdá se, že placení pomocí Google Pay na vašem zařízení v tuto chvíli není možné.'
    },
    paywayGroup: {
      inactiveMessage: 'Platba je momentálně nedostupná',
      notice: {
        novelty: 'NOVINKA',
        promotion: 'SPECIÁLNÍ NABÍDKA',
        recommended: 'DOPORUČUJEME',
        mastercard: 'RYCHLE A BEZPEČNĚ',
        mastercardC2P: 'RYCHLE A BEZPEČNĚ',
        blik: 'BLIKOMANIA – zaregistrujte se a vyhrajte diamanty!',
        visaMobile: 'Platit hladce a bezpečně'
      },
      bank: {
        many: 'bank',
        one: 'banka',
        two: 'banky'
      },
      wallet: {
        many: 'peněženek',
        one: 'peněženka',
        two: 'peněženky'
      },
      otp: {
        many: 'způsoby',
        one: 'cesta',
        two: 'způsoby'
      },
      blikPayLater: {
        info: 'Zjistit více'
      }
    },
    paywayList: {
      label: 'Skupina typů plateb',
      linkSelectOther: 'Vyberte prosím jiný'
    },
    paywaySingle: {
      accountAtAnotherBank: 'Mám účet v jiné bance'
    },
    paywaySwitch: {
      methodShortSingle: 'Jednorázový',
      methodLongSingle: 'Jednorázové platby',
      methodShortAuto: 'Cyklický',
      methodLongAuto: 'Opakované platby'
    },
    form: {
      payway: {
        label: 'Vyberte způsob platby'
      },
      card: {
        label: 'Zadejte údaje o své kartě',
        iframe: {
          title: 'Formulář s údaji o kreditní kartě, který je třeba vyplnit'
        }
      },
      visaMobile: {
        iframe: {
          title: 'Formulář s telefonním číslem k vyplnění'
        },
        status: {
          pending: {
            statusText: 'Čekání na potvrzení',
            header: 'Potvrďte platbu v aplikaci',
            descriptionText1: 'Platbu můžete potvrdit v aplikaci Visa&nbsp;Mobile nebo bankovní aplikaci s aktivní službou Visa&nbsp;Mobile',
            descriptionText2: 'Po potvrzení transakce v aplikaci čekejte na výsledek platby',
            descriptionText3: 'Probíhá zrušení transakce',
            cancelButton: 'Zrušit platbu'
          },
          success: {
            statusText: 'Děkujeme za nákup!',
            descriptionText: 'Platba byla úspěšně dokončena'
          },
          error: {
            statusText: 'Ujistěte se, že na platební kartě použité k platbě Visa&nbsp;Mobile máte dostačující prostředky.',
            descriptionText: 'Platba byla zamítnuta'
          }
        },
        apps: 'Aplikace podporující platby Visa&nbsp;Mobile',
        help: 'Potřebujete pomoc?',
        info: 'Zjistěte více',
        secure: 'Bezpečná platba díky SSL šifrování',
        modal2: {
          header: 'Pro potvrzení platby si otevřete aplikaci Visa&nbsp;Mobile nebo aplikaci banky, u které máte aktivní službu Visa&nbsp;Mobile',
          listText1: 'máte nainstalovanou aplikaci Visa&nbsp;Mobile nebo aktivní službu Visa&nbsp;Mobile v mobilní aplikaci Vaší banky',
          listText2: 'máte zapnutá upozornění v aplikaci Visa&nbsp;Mobile nebo bankovní aplikaci s aktivní službou Visa&nbsp;Mobile',
          listText3: 'Vámi zadané telefonní číslo je správné: <strong class="no-wrap">+{prefix} {phone}</strong>',
          listText4: 'Ujistěte se také, že...'
        }
      },
      click2pay: {
        loaderText: 'Hledáme vaši e-mailovou adresu<br>v <strong>Click to Pay</strong>',
        saveCard: {
          text1: 'Uložte svou kartu',
          text2: 'Vaše data budou bezpečně uložena ve službě <strong>Mastercard Click to Pay</strong>',
          link: 'Další informace'
        },
        email: {
          labelForClick2Pay: 'E-mailová adresa pro oznámení o platbách'
        },
        cardSelect: {
          selected: 'Vybráno',
          select: 'Vybrat',
          payOther: 'Zadejte údaje o kartě',
          back: 'zpět'
        },
        cardSelected: {
          header: 'Použijte kartu uloženou v Click to Pay',
          date: 'platné do',
          buttonChange: 'Change'
        },
        codeForm: {
          description: 'Našli jsme vaši e-mailovou adresu ve službě Click to Pay.',
          codeSentEmail: 'Zadejte jednorázový kód, který jsme odeslali na adresu {email}, abyste se mohli přihlásit ke svému účtu.',
          codeSentPhone: 'Zadejte jednorázový SMS kód, který jsme odeslali na číslo {phone}, abyste se mohli přihlásit ke svému účtu.',
          codeLabel: 'Zadejte kód',
          codePlaceholder: 'zadejte kód',
          codeLabelFlow: 'Kód',
          codeSendLabel: 'Odeslat nový kód',
          codeSendAriaLabel: 'via',
          codeSendAriaLabel2: 'nebo',
          buttonSendNewSms: 'SMS',
          buttonSendNewEmail: 'e-mail',
          trustedLabel: 'Přidejte toto zařízení do svého seznamu důvěryhodných<br><span>Příště toto zařízení rozpoznáme a budete platit bez přihlášení</span>',
          buttonSubmit: 'Další',
          buttonChangeCard: 'Zadejte podrobnosti o kartě'
        },
        info: {
          description: 'Platba kartou je šifrovaná a bezpečná. Transakce bude autorizována pomocí 3DSecure na webových stránkách banky.'
        },
        loginForm: {
          emailLabel: 'Zadejte svou e-mailovou adresu pro přihlášení do Click to Pay',
          emailPlaceholder: 'e-mailová adresa',
          emailLabelFlow: 'E-mail',
          buttonSubmit: 'Přihlásit se',
          buttonBack: 'Zpět'
        },
        newUserForm: {
          header: 'Uložit kartu v Click to Pay',
          description: 'K uložení vaší karty potřebujeme několik dalších podrobností.',
          emailLabel: 'Zadejte e-mailovou adresu',
          emailPlaceholder: 'e-mailová adresa',
          emailLabelFlow: 'E-mail',
          countryLabel: 'Country',
          firstnameLabel: 'Jméno',
          firstnamePlaceholder: 'name',
          firstnameLabelFlow: 'Name',
          lastnameLabel: 'Příjmení',
          lastnamePlaceholder: 'lastname',
          lastnameLabelFlow: 'Lastname',
          phoneLabel: 'Telefonní číslo',
          phonePlaceholder: 'telefonní číslo',
          phoneLabelFlow: 'Telefon',
          countryCodePlaceholder: 'kód',
          countryCodeLabelFlow: 'Kód',
          trustedLabel: 'Přidejte toto zařízení do svého seznamu důvěryhodných<br><span>Příště toto zařízení rozpoznáme a budete platit bez přihlášení</span>',
          termsLabel: 'Pokračováním přijímáte <a href="https://www.mastercard.com/global/click-to-pay/country-listing/terms.html" target="_blank">Smluvní podmínky</a> a berete na vědomí, že vaše údaje budou zpracovány v souladu s <a href="https://www.mastercard.com/global/click-to-pay/country-listing/privacy.html" target="_blank">Zásady ochrany osobních údajů</a>Mastercard.',
          c2pInfo: 'Click to Pay použije tyto informace k ověření a odeslání ověřovacích kódů na toto číslo. Mohou být účtovány poplatky za zprávy/data.',
          buttonSubmit: 'Uložit a zaplatit',
          buttonBack: 'Zaplaťte bez uložení karty'
        },
        profile: {
          header: 'Přihlaste se a zaplaťte kliknutím',
          buttonChangeEmail: 'Změnit e-mailovou adresu'
        },
        modal: {
          title: 'Click to Pay – information',
          header: 'Plaťte rychle a bezpečně, bez přepisování údajů o kartě v novém standardu Click to Pay',
          text1: 'Při platbě kartou hledejte logo <img src="/web/img/click2pay/Click_to_Pay.svg" style="height: 14px" alt="Click to Pay Logo"> a zaplaťte svou platební kartou rychle a pohodlně.',
          text2: 'Click To Pay je nový standard pro platby kartou podporovaný společnostmi Visa a Mastercard.',
          text3: 'Kartou na svém Click to Pay účtu můžete platit v obchodech v Polsku i v zahraničí, aniž byste museli kopírovat údaje o kartě.',
          text4: 'Data jsou bezpečně uložena ve Visa nebo Mastercard. Všechny informace jsou plně šifrovány.',
          text5: 'Během dalších plateb Click to Pay rozpozná vaši e-mailovou adresu, takže můžete okamžitě platit uloženými kartami.',
          text6: 'Dodatečně potvrzujete platby u své banky, což zaručuje plnou bezpečnost.',
          processError: 'Při provádění platby kliknutím na tlačítko došlo k chybě. Proveďte standardní platbu kartou.'
        }
        },
      email: {
        label: 'Zadejte svou emailovou adresu',
        flowLabel: 'E-mail',
        tip: 'Potřebujeme vaši e-mailovou adresu, abychom vám mohli zasílat zprávy o stavu platby.'
      },
      terms: {
        header: 'Souhlasy a prohlášení',
        selectAll: 'Vybrat vše',
        fieldNotRequired: 'Nepovinné pole',
        partnerMarketingTextLess: 'Chcete dostávat e-mailové, SMS nebo telefonické upozornění na nejlepší nabídky, akce, soutěže a další zajímavé akce pořádané námi nebo našimi partnery?',
        partnerMarketingTextMore: 'Slibujeme, že nebudeme spamovat! Pokud vás naše novinky nudí, můžete svůj souhlas kdykoli odvolat.',
        tradeMarketingTextLess: 'Chcete, abychom mohli předat váš e-mail nebo telefonní číslo našim důvěryhodným partnerům, aby vám mohli prezentovat zajímavé informace a nabídky?',
        tradeMarketingTextMore: 'Ujišťujeme vás, že skupina našich Partnerů jsou uznávané společnosti, které stejně jako my nemají rády spam, a svůj souhlas můžete kdykoli odvolat.'
      },
      termsLabelDefault: 'Seznámil jsem se a akceptuji <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Stáhněte si soubor s pravidly služby" rel="noreferrer">Pravidla</a> poskytování platebních služeb a <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Stáhněte si soubor s politikou soukromí Autopay S.A.">Politiku soukromí</a>. Chci, aby služba byla poskytnuta neprodleně, a v případě odstoupení od smlouvy si jsem vědom toho, že neobdržím zpět náklady vynaložené na služby poskytnuté na mé vyžádání do okamžiku odstoupení od smlouvy.',
      termsReccuring: 'Pokud chcete provést platbu, odsouhlaste <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Stáhněte si soubor s pravidly služby" rel="noreferrer">Pravidla</a> a <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Stáhněte si soubor s politikou soukromí Autopay S.A.">Politiku soukromí</a> Autopay S.A., souhlas s okamžitým poskytnutím platební služby a souhlas se stržením částky z mé platební karty.',
      termsReccuringMore1: 'Souhlasím s tím, aby služba byla poskytnuta neprodleně, a v případě odstoupení od smlouvy si jsem vědom toho, že neobdržím zpět náklady vynaložené na služby poskytnuté na mé vyžádání do okamžiku odstoupení od smlouvy.',
      termsReccuringMore2: 'Souhlasím s tím, že Autopay S.A. bude z mé karty periodicky strhávat částky za účelem provádění transakcí ve prospěch {receiverName}. Pravidla periodického strhávání částek z karty stanoví <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Stáhněte si soubor s pravidly služby" rel="noreferrer">Pravidla poskytování platebních služeb</a>. Prohlašuji, že jsou mi známy termíny, frekvence a pravidla stanovení částek, které budou odepsány z mé platební karty, a také způsob odvolání tohoto souhlasu, které mnou byly dohodnuty s {receiverName}. V případě dotazů souvisejících s periodickým strháváním částek z platební karty budu kontaktovat {receiverName}.',
      mwfClauseLess: 'Pokud chcete provést platbu, přijměte <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Stáhněte si soubor s podmínkami služby" rel="noreferrer">Pravidla</a> i  <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Stáhněte si soubor se Zásadami ochrany osobních údajů společnosti Autopay S.A.">Zásady  ochrany osobních údajů</a> Autopay S.A. a souhlas s okamžitým provedením platební služby.',
      mwfClauseMore: 'Souhlasím s tím, že služba bude poskytnuta okamžitě a vím, že v případě odstoupení od smlouvy mi nebudou vráceny peníze.',
      mwfClauseARIA: 'Pokud chcete provést platbu, přijměte Regulamin i  Zásady  ochrany osobních údajů Autopay S.A. a souhlas s okamžitým provedením platební služby. Souhlasím s tím, že služba bude poskytnuta okamžitě a vím, že v případě odstoupení od smlouvy mi nebudou vráceny peníze. <a href="{regulationUrl}" class="form-text-link" target="_blank" title="" rel="noreferrer" tabindex="-1">Stáhněte si soubor s podmínkami služby</a> <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="" tabindex="-1">Stáhněte si soubor se Zásadami ochrany osobních údajů společnosti Autopay S.A.</a>',
      paymentInfo: 'Platební příkaz je zadán prostřednictvím Autopay S.A. sídlem w  Sopoty a  bude zpracován v souladu s podmínkami stanovenými vaší bankou. Po  zvolení  banky autorizujete platbu.',
      changePaywayLink1: 'Stále můžete',
      changePaywayLink2: 'změnit způsob platby',
      linkMore: 'VÍCE',
      linkShowMoreTitle: 'Zobrazit dodatečné informace',
      linkLess: 'MÉNĚ',
      linkShowLessTitle: 'Skryjte dodatečné informace',
      submitButton: {
        visaMobile: 'Platit podle',
        fastTransfer: 'Vygenerujte data pro převod',
        mwf: 'Přijímám a platím',
        pis: 'Přijímám a zahajuji platbu',
        auto: 'Objednejte si opakovanou platbu',
        autoPayment: 'Platba objednávky',
        other: 'Zaplatit',
        orange: 'Potvrdit',
        c2pNext: 'Další',
        c2pAccept: 'Přijímám',
        c2pSaveAndPay: 'Uložit a zaplatit'
      }
    },
    frData: {
      pageTitle: 'Podrobné údaje pro převod',
      header: 'Proveďte převod pomocí poskytnutých  dat',
      nrbLabel: 'Účet příjemce',
      nameLabel: 'Jméno příjemce',
      addressLabel: 'Adresa příjemce',
      titleLabel: 'Variabilní symbol',
      amountLabel: 'Částka',
      timeLabel: 'Proveďte transakci do',
      linkCopy: 'Kopíruj',
      linkGetPdf: 'Stáhněte si PDF s daty',
      linkGetPdfTitle: 'Otevře se v novém okně',
      linkGoToBank: 'Přihlaste se do banky',
      info: 'Přihlaste se ke svému účtu v bance. Zkopírujte údaje do převodu. Je důležité, aby údaje byly stejné, neměňte částky, měny, popisy, atd.'
    },
    processing: {
      pageTitle: 'Čekací stránka na zpracování transakce',
      loaderAnimation: 'Animace zobrazená během čekání',
      header: 'Připravujeme vaši platební stránku.',
      description: 'Počkejte ještě chvíli...',
      info: {
        header: 'Pamatujte si!',
        description: 'Po dokončení transakce stránku nezavírejte – pokud tak učiníte, vaše peníze nebudou odeslány příjemci.'
      },
      loaderText: 'Probíhá přenos dat.<br>Zabere nám to maximálně 30  sekund.'
    }
  },
  thankYou: {
    pageTitle: 'Informace o výsledku transakce',
    form: {
      emailLabel: 'E-mail',
      buttonSubmit: 'Vraťte se na stránku partnera'
    },
    negative: {
      header: 'Platba nebyla zpracována z důvodu chybějící autorizace transakce.',
      description: 'Pokud i přesto chcete využít nabídku daného prodejce nebo poskytovatele služeb – vraťte se na jejich stránky, vyberte si produkt nebo službu a zaplaťte způsobem, který si zvolíte.'
    },
    pending: {
      header: 'Děkujeme za zvolení platby prostřednictvím platební brány.',
      description: 'Ověřuje se stav transakce. Po dokončení procesu obdržíte informaci o stavu platby na vaši e-mailovou adresu.'
    },
    positive: {
      header: 'Děkujeme, vaše platba byla zpracována.',
      description: 'Potvrzení zašleme na vámi uvedenou e-mailovou adresu.'
    }
  },
  regulationsApproval: {
    header: 'Přijměte transakci'
  },
  wait: {
    pageTitle: 'Stránka čekání'
  },
  sessionTimeout: {
    pageTitle: 'Stránka po ukončení sezení',
    header: 'Čas pro dokončení platby vypršel',
    description: 'Na webové stránky partnera se můžete kdykoli vrátit, znovu zadat objednávku nebo využít službu a zaplatit způsobem, který si zvolíte.'
  },
  paywayError: {
    pageTitle: 'Vybraný platební kanál není dostupný',
    header: 'Vybraná platební metoda je bohužel momentálně nedostupná.',
    description: 'Vraťte se prosím na stránku partnera a zkuste to znovu později'
  },
  loader: {
    defaultAnimation: 'Při načítání stránky se zobrazuje animace',
    slider: 'Posuvník s měnícími se texty',
    text1: 'Usilujeme o uhlíkovou neutralitu.Také  to  děláte  když využíváte naše služby.',
    text2: 'Do práce jezdíme na kole, čímž omezujeme emise skleníkových plynů do atmosféry. Děláte to také prostřednictvím našich služeb. ',
    text3: 'Jsme poháněni zelenou energií. Vy také, když využíváte naše služby.',
    text4: 'Omezujeme používání papíru. Děláte to také prostřednictvím našich služeb.',
    rateLimitExceeded: 'There is increased transaction traffic, so processing your payment may take longer than usual. Please wait and do not refresh the payment page.'
  },
  orderDetails: {
    header: 'Detaily',
    headerAriaLabel: 'Detaily transakce',
    amountLabel: 'Částka pohledávky',
    receiverLabel: 'Příjemce',
    orderLabel: 'Číslo objednávky',
    amountOryginalCurrencyLabel: 'Částka v {currency}',
    currencyRateLabel: 'Směnný kurz',
    feeLabel: 'Poplatek za transakci',
    customerNumberLabel: 'Číslo zákazníka',
    invoiceNumberLabel: 'Číslo faktury',
    accountNumberLabel: 'Číslo účtu',
    paywayLabel: 'Způsob platby',
    paywayName: 'Online bankovním převodem',
    linkBack: 'Návrat do obchodu',
    linkBackTitle: 'Opustit transakci a vrátit se do obchodu',
    linkMore: 'více',
    linkLess: 'méně',
    toggleBtnUnfoldAriaLabel: 'Rozbalte podrobnosti objednávky',
    toggleBtnFoldAriaLabel: 'Sbalit podrobnosti objednávky'
  },
  error: {
    pageTitle: 'Chybová stránka',
    linkBackUrlSet: 'Vraťte se na stránku partnera',
    linkBackUrlNotSet: 'Přejděte na stránku nápovědy',
    sessionTimeout: {
      title: 'Čas pro dokončení platby vypršel',
      message: 'Na webové stránky partnera se můžete kdykoli vrátit, znovu zadat objednávku nebo využít službu a zaplatit způsobem, který si zvolíte.'
    },
    notFoundError: {
      pageTitle: 'Chybová stránka 404 – stránka nenalezena',
      title: 'Vypadá to, že jste zašli příliš daleko a nenašli jste stránku, kterou chcete navštívit.',
      message: 'Možné důvody: stránka neexistuje nebo byla smazána, probíhají servisní práce, adresa stránky je nesprávná, došlo k technické chybě.'
    },
    generalError: {
      title: 'Omlouváme se',
      message: 'V tuto chvíli nemůžeme váš převod zpracovat.'
    },
    errFieldNotFound: {
      title: 'Omlouváme se',
      message: ''
    },
    errBadClientSource: {
      title: 'Omlouváme se',
      message: ''
    },
    nrParametersError: {
      title: 'Omlouváme se',
      message: 'V  tuto chvíli nemůžeme váš převod zpracovat.'
    },
    transactionOutdated: {
      title: 'Čas pro dokončení platby vypršel.',
      message: 'Zadejte ji znovu.'
    },
    linkValidityTimeOutdated: {
      title: 'Čas pro dokončení platby vypršel.',
      message: 'Zadejte ji znovu.'
    },
    transactionValidityTimeOutdated: {
      title: 'Čas pro dokončení platby vypršel.',
      message: 'Zadejte ji znovu.'
    },
    multiplyTransaction: {
      title: 'Omlouváme se',
      message: 'Transakce již existuje a čeká na platbu.'
    },
    transactionCanceled: {
      title: 'Omlouváme se',
      message1: 'Transakce byla zrušena.',
      message2: 'Zadejte ji znovu.'
    },
    multiplyPaidTransaction: {
      title: 'Omlouváme se',
      message: 'Transakce je již zaplacena.'
    },
    transactionReject: {
      title: 'Omlouváme se',
      message1: 'Jejda! Něco se pokazilo.',
      message2: 'Bránu už opravujeme...'
    },
    bankDisabled: {
      title: 'Omlouváme se',
      message: 'Vybraná banka je momentálně nedostupná.'
    },
    bankTemporaryMaintenance: {
      title: 'Omlouváme se',
      message: 'V tuto chvíli nejsme schopni zpracovat váš převod.'
    },
    insufficientStartAmount: {
      title: 'Omlouváme se',
      message: 'Byl překročen limit převodové částky.'
    },
    startAmountOutOfRange: {
      title: 'Omlouváme se',
      message: 'Byl překročen limit převodové částky.'
    },
    nonAccountedLimitExceeded: {
      title: 'Omlouváme se',
      message: 'Platbu nelze provést, protože prodejce dosáhl měsíčního limitu prodeje.'
    }
  },
  confirmation: {
    pageTitle: 'Informace o průběhu platby',
    linkBack: 'Vraťte se na stránku',
    linkBackTimeout: 'Návrat na partnerský web za ',
    incorrectAmount: {
      header: 'Chybná částka',
      description: 'Vaši platbu jsme příjemci nepřevedli, protože částka byla nesprávná.',
      description2: 'Klepnutím na tlačítko se vrátíte na stránku a zopakujete to. Nezapomeňte neměnit žádná data.'
    },
    multiplePaid: {
      header: 'Dvojitý vklad',
      description: 'Příjemce obdržel vaši platbu dvakrát. Nebojte se – peníze vám budou vráceny.',
      description2: 'Kontaktujte příjemce a poskytněte mu potvrzení o platbě.'
    },
    negative: {
      header: 'Žádná platba',
      description: 'Při zpracování vaší platby došlo k chybě. Ale nic není ztraceno.',
      description2: 'Klikněte na tlačítko pro návrat na stránku a opakujte to.'
    },
    notFound: {
      header: 'Žádná platba',
      description: 'Při zpracování vaší platby došlo k chybě. Ale nic není ztraceno.',
      description2: 'Klikněte na tlačítko pro návrat na stránku a opakujte to.'
    },
    outdated: {
      header: 'Konec času',
      description: 'Vaši platbu jsme nepřevedli příjemci.',
      description2: 'Klikněte na tlačítko pro návrat na stránku a opakujte to.'
    },
    outdatedPaidNotRefunded: {
      header: 'Konec času',
      description: 'Vaši platbu jsme nepřevedli příjemci. Pokud byly z vašeho účtu staženy peníze, nemějte obavy, peníze obdržíte během několika dní.',
      description2: 'Klikněte na tlačítko pro návrat na stránku a opakujte to.'
    },
    outdatedPaidRefunded: {
      header: 'Konec času',
      description: 'Vaši platbu jsme nepřevedli příjemci. Pokud byly peníze staženy z vašeho účtu, nemějte obavy, již jsme vám je vrátili.',
      description2: 'Klikněte na tlačítko pro návrat na stránku a opakujte to.'
    },
    pending: {
      header: 'Probíhá',
      description: 'Zpracováváme vaši platbu. Až dorazí k příjemci, budeme vás informovat.'
    },
    positive: {
      header: 'Zaplaceno',
      visaVideo: 'Reklamní video přehráno po úspěšné transakci',
      description: 'Převedli jsme vaše prostředky příjemci.'
    }
  },
  messages: {
    accessForbidden: 'Chybí přístup',
    internalServerError: 'Došlo k neočekávané chybě. Prosím zkuste to znovu později.',
    validators: {
      required: 'Povinné pole',
      pattern: 'Zadejte platnou hodnotu',
      emailRequired: 'Prosím zadejte platnou emailovou adresu',
      emailNotValid: 'Prosím zadejte platnou emailovou adresu',
      emailNotRecognized: 'Zadaná e-mailová adresa není registrována',
      emailIdentityLookup: 'Nepodařilo se ověřit e-mailovou adresu',
      emailLocked: 'Zadaná e-mailová adresa je dočasně zablokována v systému Mastercard',
      paywayRequired: 'Chcete-li zaplatit, vyberte banku',
      creditCardRequired: 'Vyplňte všechna pole',
      phoneRequired: 'Vložte prosím platné telefonní číslo',
      regulationRequired: 'Přijměte obchodní podmínky',
      requiredConsent: 'Vyžadován souhlas',
      codeNotValid: 'Zadaný kód není platný',
      codeAccountLocked: 'Účet byl dočasně zablokován v systému Mastercard'
    },
    validatorsAria: {
      required: 'Upozornění. Pole formuláře nebylo vyplněno. Povinné pole',
      pattern: 'Pole obsahuje neplatnou hodnotu.',
      emailRequired: 'Upozornění. Pole e-mailu nebylo vyplněno. Zadejte platnou e-mailovou adresu',
      emailNotValid: 'Upozornění. Vaše e-mailová adresa obsahuje chybu. Zadejte platnou e-mailovou adresu',
      emailNotRecognized: 'Pozor. Uvedená e-mailová adresa není registrována.',
      emailIdentityLookup: 'Pozor. Zadanou e-mailovou adresu nebylo možné ověřit',
      emailLocked: 'Pozor. Uvedená e-mailová adresa je dočasně zablokována v systému Mastercard.',
      paywayRequired: 'Upozornění. Platba nebyla vybrána. Vyberte způsob platby',
      creditCardRequired: 'Upozornění. Vyplňte všechna pole podrobnostmi o kreditní kartě',
      phoneRequired: 'Pozor. Políčko telefonní číslo obsahuje chybu. Zadejte správné telefonní číslo.',
      codeNotValid: 'Pozor. Zadaný kód není platný.',
      codeAccountLocked: 'Pozor. Účet byl dočasně zablokován v systému Mastercard.'
    },
    success: {
      copy: 'Zkopírováno!',
      codeSent: 'Kód byl odeslán!'
    },
    errors: {
      getPdf: 'Stažení souboru PDF se nezdařilo!',
      copy: 'Kopírování se nezdařilo!',
      thankYouUpdate: 'E-mailovou adresu nelze uložit!',
      groupInactive: 'V současné době není možné provést převod pomocí skupiny {groupName}. Vyberte prosím jiný způsob platby.',
      paywayInactive: 'Tento způsob platby je momentálně nedostupný. Vyberte prosím jiný.',
      googlePayInactive: 'Google Pay bohužel není na vašem zařízení k dispozici.',
      applePayInactive: 'Platba ApplePay vyžaduje použití Safari a zařízení Apple kompatibilní s touto platební metodou.',
      codeNotSent: 'Nepodařilo se znovu odeslat kód!',
      codeCounterExceeded: 'Byl překročen počet povolených pokusů. Zadejte údaje o své kartě ručně nebo použijte jinou platební metodu.'
    }
  },
  modal: {
    closeButton: 'Zavřít',
    confirmButton: 'Rozumím'
  },
  configuration: {
    paywayGroups: {
      auto: {
        label: 'Opakovaná platba',
        description: 'Zadejte údaje o kartě, kterou budete používat k platbě. Automatické platby budou z vaší karty strženy později.',
        labelInitWithRefund: 'Ověřovací platb',
        labelInitWithRefundOrange: 'Přidejte platební kartu',
        labelInitWithPayment: 'Platba kartou',
        descriptionInitWithRefund: 'Zadejte údaje karty, kterou chcete ověřit pro budoucí platby. V dalším kroku ze zadané karty strhneme jeden zlotý, abychom ověřili správnost údajů. Po ověření tuto částku vrátíme.',
        descriptionInitWithPayment: 'Zadejte údaje karty, kterou provedete transakci a současně ji ověříte pro budoucí platby.'
      },
      blik: {
        label: 'BLIK',
        description: 'Zaplaťte ihned zadáním kódu BLIK, bez přihlašování a registrace'
      },
      card: {
        label: 'Platba kartou',
        description: 'Zaplaťte svou platební kartou'
      },
      pbl: {
        label: 'Online bankovním převodem',
        description: 'Vyberte banku, ze které chcete provést platbu',
        descriptionVerify: 'On-line ověření identity',
        descriptionServiceVerify: 'Vyberte banku, která vám umožní ověřit on-line službu'
      },
      visaMobile: {
        label: 'Visa Mobile',
        description: 'Mobilní platba Visa'
      },
      wallet: {
        label: 'Virtuální peněženka',
        description: 'Plaťte bez přihlášení do internetového bankovnictví'
      },
      installments: {
        label: 'Rozložit na splátky',
        description: ''
      },
      alior: {
        descriptionInfo1: 'Plaťte za své nákupy pomocí pohodlných splátek',
        descriptionInfo2: '(zkontrolovat podrobnosti)',
        aliorModal: {
          text1: 'V závislosti na nastavení webu, kde nakupujete, můžete nakupovat produkty na splátky v následujících variantách:',
          text2: '<ul><li>10 splátek 0%</li><li>20 splátek 0%</li><li>od 3 do 48 splátek s měsíčními náklady 1% (podle uvedeného reprezentativního příkladu pro danou transakci)</li></ul>'
        }
      },
      otp: {
        label: 'Zaplatit později',
        description: 'Kupte nyní a plaťte později',
        descriptionInfo1Mwf: 'Účet zaplaťte později – až 45 dní najednou nebo v několika stejných splátkách',
        descriptionInfo1Ecommerce: 'Plaťte později – až 45 dní najednou nebo v několika stejných splátkách',
        descriptionInfo2Mwf: 'Informace o zprostředkovateli úvěru a nákladech',
        descriptionInfo2Ecommerce: 'Informace o nákladech',
        labelPayka: 'Payka',
        descriptionPayka: 'Účet zaplaťte později – jednorázově do 30 dnů nebo ve 4 rovných splátkách <a href="" id="paykaShowInModal">Informace o zprostředkovateli úvěru a nákladech</a>',
        paykaModalMwf: {
          header1: 'Informace o úvěrovém zprostředkovateli',
          text1: 'Autopay S.A. se sídlem v Sopotech působí jako úvěrový zprostředkovatel společnosti Paytree S.A. se sídlem v Gdaňsku (poskytovatel úvěru), oprávněný k předkládání nabídky úvěru klientům a přesměrovávání na webové stránky poskytovatele úvěru, a také na úvěrovou žádost. Podrobnosti nabídky najdete na: <a href="https://payka.pl/rachunki-domowe" target="_blank">Rachunki domowe - Payka</a>.',
          header2: 'Údaje týkající se nákladů',
          text2: 'Plaťte později – až až 45 dní najednou nebo v několika stejných splátkách. Údaje o nákladech pro reprezentativní příklad: Splacení do 45 dnů RPSN 0 %; Splátka RPSN 86,83 %. Podrobnosti o nabídce na: <a href="https://payka.pl" target="_blank">Payka.pl</a>'
        },
        paykaModalEcommerce: {
          header2: 'Údaje týkající se nákladů',
          text2: 'Plaťte později – až až 45 dní najednou nebo v několika stejných splátkách. Údaje o nákladech pro reprezentativní příklad: Splacení do 45 dnů RPSN 0 %; Splátka RPSN 86,83 %. Podrobnosti o nabídce na: <a href="https://payka.pl" target="_blank">Payka.pl</a>'
        }
      },
      blikPayLater: {
        label: 'BLIK Zaplatit později',
        description: 'Služba je dostupná u Millenium Bank a VeloBank',
        modal: {
          header1: 'Kupujte nyní, plaťte ve lhůtě 30 dnů',
          header2: 'BLIK kód',
          text2: 'Zadáte kód BLIK a my vám nabídneme nákupní limit až 4 000 PLN',
          header3: 'Zakoupíte produkty a zaplatíte za ně později',
          text3: 'Máte čas zkontrolovat, zda vám vše vyhovuje',
          header4: 'Zaplatíte do 30 dnů zdarma',
          text4: 'Své nákupy můžete také vrátit ve lhůtě určené obchodem'
        }
      },
      companyPayments: {
        label: 'Firemní platby',
        description: 'Přesuňte termín splatnosti u firemních nákupů o 30 dnů'
      },
      transfer: {
        label: 'Údaje o bankovním převodu',
        description: 'Proveďte převod pomocí poskytnutých dat'
      },
      other: {
        label: 'Další',
        description: ''
      }
    },
    mobileEvents: {
      statusDescription: {
        bankDisabled: 'Omlouváme se. Momentálně nemůžeme zpracovat váš převod. Vybraná brána je momentálně nedostupná.',
        blockMultipleTransactions: 'Omlouváme se. Transakce již existuje a čeká na platbu.',
        blockPaidTransactions: 'Omlouváme se. Transakce již byla zaplacena. Nemůžete znovu dokončit platbu.',
        canceledTransactions: 'Transakce, kterou se pokoušíte zaplatit, byla zrušena a není dostupná. Začněte prosím novou transakci. ',
        declined: 'Autorizace zamítnuta.',
        generalError: 'Došlo k dočasnému problému s připojením. V tuto chvíli nemůžeme váš převod zpracovat. Vraťte se prosím později.',
        insufficientStartAmount: 'Omlouváme se. Byl překročen limit převodové částky.',
        internalServerError: 'Jejda .. něco se nepovedlo :(. Na odstranění problému pracujeme. Zkuste to prosím znovu později.',
        nonAccountedLimitExceeded: 'Platbu nelze provést, protože prodejce dosáhl měsíčního limitu prodeje.',
        outdatedError: 'Čas pro dokončení platby uplynul..',
        paid: 'Transakce byla úspěšně dokončena.',
        paywayLoaded: 'Stránka rychlého přenosu byla načtena',
        paywaylistLoaded: 'Načtena stránka pro výběr platebních metod'
      }
    }
  },
  maintenance: {
    text1: 'Na platební bráně Autopay probíhá novoroční úklid, proto v tuto chvíli nelze platbu objednat. Omluvám se za nepříjemnosti.',
    text2: 'Brána bude opět fungovat 16   ledna v 10:00.',
    text3: 'Děkujeme za pochopení.'
  },
  requiredParams: {
    accountHolderName: {
      label: 'Zadejte podrobnosti o vlastníkovi účtu',
      flowLabel: 'majitel účtu',
      placeholder: 'Zadejte podrobnosti o vlastníkovi účtu'
    },
    nip: {
      label: 'Zadejte své daňové identifikační číslo',
      flowLabel: 'NIP',
      placeholder: 'Zadejte své daňové identifikační číslo'
    },
    customerEmail: {
      label: 'Zadejte svou e-mailovou adresu',
      flowLabel: 'E-mail',
      placeholder: 'Zadejte svou e-mailovou adresu'
    }
  }
}
